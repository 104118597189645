import ReportLayout from "./ReportLayout";
import { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import DatasheetDetailsTab from "../../components/Reports/DatasheetDetailsTab";
import ReportDetailsTab from "../../pages/ReportDetails/ReportDetailsTab";
import Loader from "../../components/Common/Loader";
import { Alert, AlertTitle, Snackbar, Box } from "@mui/material";
import { SYNAPSE_API } from "../../api/Api";
import { getAllRequiredDetailsForReport } from "./fetchData";
import { useDispatch, useSelector } from "react-redux";
import AnswerEngine from "../AnswerEngine/AnswerEngine";
import { downloadPdfFromUrlAndMemoized, MyRABtn } from "../../utils/common";
import Snippet from "../ReportSection/Snippet";

function FlashReportDetails() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userData);

  const { id: hub, slug } = useParams();

  const [currentTab, setCurrentTab] = useState(0);
  const [, setReportId] = useState("");
  const [, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [detailsData, setDetailsData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [reportTabsToRender, setReportTabsToRender] = useState([]);
  const [triggerApiCall, setTriggerApiCall] = useState(true);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const moveToTop = setTimeout(() => {
      window.scroll(0, 350);
    }, 0);
    return () => {
      clearTimeout(moveToTop);
    };
  }, [currentTab]);

  const fetchReport = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchReportDataBySlug(slug, setSnackbarOpen);

      let pdfUrl = null;
      if(data?.custom?.report_pdfs.length > 0) {
        pdfUrl = data.custom.report_pdfs[0]?.url;
      } else {
        pdfUrl = data?.report_sample_url;
      }
      downloadPdfFromUrlAndMemoized(pdfUrl).then(
        (pdf) => {
          dispatch({
            type: "SET_INITIAL_REPORT_BUFFER",
            payload: {
              url: pdfUrl,
              data: pdf,
            },
          });
        }
      );
      let tabsToShow = [];

      // ONLY FOR UPLOADED REPORTS
      if (data.subscribed === "yes") {
        data['upload_id'] = userDetails.id;
        if(!data.upload_id || !data.report_id) return;
        tabsToShow = await getAllRequiredDetailsForReport(
          data,
          setTriggerApiCall,
          dispatch,
          setCurrentTab
        );
      }

      if (data === null) {
        setReportTabsToRender([]);
        setCurrentTab(null);
        return;
      }

      if(tabsToShow === null) tabsToShow = [];

      // ADDING SNIPPET TAB
      if(data.subscribed === "no") {
        tabsToShow.push({
          name: "Snippet",
          component: <Snippet fetchReport={fetchReport} slug={slug} />,
        });
      }

      if (data.report_sample_url || data?.custom?.report_pdfs.length > 0) {
        tabsToShow.push({
          name: "Report",
          component: (
            <ReportDetailsTab
              subscribed={data?.subscribed}
              sampleUrl={data?.report_sample_url}
              blurUrl={data?.blur_pdf_url}
              reportUrl={data?.report_url}
              id={data?.id}
              title={data?.title}
              reportPdf={data?.custom?.report_pdfs}
              isFlash={
                data?.custom?.report_pdfs && data?.subscribed === "yes"
                  ? true
                  : false
              }
            />
          ),
        });
      }

      if (data?.custom?.report_excel) {
        tabsToShow.push({
          name: "Data Sheet",
          component: (
            <DatasheetDetailsTab reportExcel={data?.custom?.report_excel} />
          ),
        });
      }

      if (data?.custom?.report_pdfs.length > 0) {
        // SOME EXCEPTIONS IN WHICH MYRA BUTTON SHOULD NOT BE SHOWN FOR THESE LIBRARY REPORTS
        tabsToShow.push({
          name: <MyRABtn />,
          component: (
            <AnswerEngine
              subscribed={data?.subscribed === "yes"}
              fetchReport={fetchReport}
              title={data.title}
              id={data.report_id}
              from={"flash"}
            />
          ),
        });
      }

      setReportTabsToRender(tabsToShow.map((tab) => tab.name));
      setDetailsData(tabsToShow);
      setReportId(data?.report_id);
      setIsSubscribed(data?.subscribed === "yes" ? true : false);
      setReportData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [slug, userDetails.id]);

  useEffect(() => {
    if(!userDetails.id) return;
    fetchReport();
  }, [triggerApiCall, slug, userDetails.id]);

  const createTabBasedOnReport = (currentTab) => {
    const tabFactory = {};
    detailsData.forEach((tab, i) => {
      tabFactory[i] = tab.component;
    });

    return (
      tabFactory[currentTab] ?? <InvalidReportUi />
    );
  };

  return (
    <ReportLayout
      {...{
        setCurrentTab,
        currentTab,
        hub,
        reportTabsToRender,
        reportData,
        fetchReport,
        loading,
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Box
          component="main"
          sx={{
            display: "flex",
            justifyContent: " center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", maxWidth: "1680px", padding: "1rem" }}>
            {createTabBasedOnReport(currentTab)}
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleSnackbarClose} severity="error">
              <AlertTitle>Error</AlertTitle>
              Report not found — <strong>check it out!</strong>
            </Alert>
          </Snackbar>
        </Box>
      )}
    </ReportLayout>
  );
}

export default FlashReportDetails;

const fetchReportDataBySlug = async (slug, setSnackbarOpen) => {
  try {
    let reportData = await SYNAPSE_API.get(
      `/api/v1/flash/report-by-slug/${slug}`
    );
    return reportData?.data?.data;
  } catch (error) {
    setSnackbarOpen(true);
    console.error(error);
    return null;
  }
};

const InvalidReportUi = () => {
  return (
    <Box
      component="center"
      sx={{
        height: "30vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>This Report is invalid or some error has occurred</h1>
    </Box>
  );
};
